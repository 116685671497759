var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('InvoiceModal',{attrs:{"trip":_vm.invoiceModalDetails,"symbol":_vm.symbol}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('div',[_c('title-plus',{attrs:{"title":_vm.$t('components.triggerPaymentManagement.headline'),"hide-plus":true}})],1),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)])]),(_vm.$acl.canView('transaction'))?[_c('FSTable',{attrs:{"fst-id":"triggerPayment","endpoint":_vm.getEndpoint,"headers":_vm.getTableHeaders,"qso":{ prepend: '', append: '' },"currency-enabled":true,"exportType":_vm.exportKey.TRIGGER_PAYMENT,"exportFromURL":true,"currency-attributes":_vm.getCurrencyAttributes},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":"triggerPayment","slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,on:{"meta":function (e) { return (_vm.indexMetaData = e); }}},[(item.trip && item.trip !== '')?_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{ name: 'ViewTrip', params: { id: item.trip } }}}):_vm._e(),(!item.trip || item.trip === '')?_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"truncate-prepend":"#"}}):_vm._e(),_c('FSTableRowItem',[(item.user)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.user.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1),_c('FSTableRowItem',[(item.payment_method && item.payment_method !== '--')?_c('i',{class:_vm.getPaymentCardIconClass(item.payment_method_details)}):_vm._e(),(item.payment_method && item.payment_method !== '--')?_c('span',[_vm._v("  ")]):_vm._e(),_vm._v(" "+_vm._s(item.payment_method)+" ")]),_c('FSTableRowItem',{attrs:{"text":item,"as-currency":""}}),_c('FSTableRowItem',{attrs:{"text":item.checkout.updated_at,"date":""}}),_c('FSTableRowItem',{attrs:{"text":item.checkout.checkout_status}}),_c('FSTableRowItem',{attrs:{"text":item.checkout.retry_count.toString()}}),_c('FSTableRowItem',{attrs:{"text":item.payment_reference_id,"text-fallback":"--"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var text = ref.text;
return [(text === '--')?_c('span',[_vm._v("--")]):_c('span',[(_vm.paymentMethod === 'Stripe')?_c('span',{staticClass:"text-blue-500"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + text)}},[_vm._v(_vm._s(_vm.truncate(text, -5, '')))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                              ("https://dashboard.stripe.com/payments/" + text)
                            )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])]):_c('span',[_vm._v(_vm._s(_vm.truncate(text, -5, '')))])])]}}],null,true)}),_c('FSTableRowItem',[_c('div',{staticClass:"flex gap-1"},[(
                        item.checkout.checkout_status !== 'FINALIZE_SUCCESS'
                      )?_c('div',[(item.checkout.result_description)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(("" + (item.checkout.result_description))),expression:"`${item.checkout.result_description}`",modifiers:{"top":true}}],staticClass:"refund-action refund",on:{"click":function($event){return _vm.onTrigger({
                            invoiceId: item.id,
                            amount: item.amount,
                            symbol: item.currency.symbol || '$',
                          })}}},[_vm._v(" Trigger ")]):_c('div',{staticClass:"refund-action refund",on:{"click":function($event){return _vm.onTrigger({
                            invoiceId: item.id,
                            amount: item.amount,
                            symbol: item.currency.symbol || '$',
                          })}}},[_vm._v(" Trigger ")])]):_vm._e(),(
                        item.checkout.checkout_status === 'FINALIZE_SUCCESS'
                      )?_c('div',{staticClass:"refund-action refund disabled"},[_vm._v(" Trigger ")]):_vm._e(),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"id":("more-actions-" + itemIndex),"data":item},on:{"delete":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""},on:{"meta":function (e) { return (_vm.indexMetaData = e); }}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),(item.trip && item.trip !== '')?_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"truncate-prepend":"#","to":{ name: 'ViewTrip', params: { id: item.trip } }}}):_vm._e(),(!item.trip || item.trip === '')?_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"truncate-prepend":"#"}}):_vm._e(),_c('FSTableRowItem',{attrs:{"text":item.checkout.checkout_status}}),_c('FSTableRowItem',{attrs:{"text":item,"as-currency":""}})],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.table.columns.rider' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(item.user)?_c('router-link',{staticClass:"font-extrabold text-blue-600 capitalize",attrs:{"to":{
                          name: 'ViewRiderUserProfile',
                          params: { id: item.user.id },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")]):_c('span',[_vm._v(" -- ")])],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.table.columns.creditCard' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(
                          item.payment_method && item.payment_method !== '--'
                        )?_c('i',{class:_vm.getPaymentCardIconClass(item.payment_method_details)}):_vm._e(),(
                          item.payment_method && item.payment_method !== '--'
                        )?_c('span',[_vm._v("  ")]):_vm._e(),_vm._v(" "+_vm._s(item.payment_method)+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.table.columns.lastTriggeredAt' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( item.checkout.updated_at, 'D MMM, YY hh:mm:ss a' ) || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.table.columns.retryCount' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.checkout.retry_count.toString() || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.table.columns.paymentRef' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(item.payment_reference_id)?_c('span',[(_vm.paymentMethod === 'Stripe')?_c('span',{staticClass:"text-blue-500"},[_c('a',{attrs:{"target":"_blank","href":("https://dashboard.stripe.com/payments/" + (item.payment_reference_id))}},[_vm._v(_vm._s(_vm.truncate(item.payment_reference_id, -5, '')))]),_c('span',{staticClass:"cursor-pointer text-gray-500",on:{"click":function($event){return _vm.copyTextToClipboard(
                                ("https://dashboard.stripe.com/payments/" + (item.payment_reference_id))
                              )}}},[_c('i',{staticClass:"ml-2 far fa-copy"})])]):_c('span',[_vm._v(_vm._s(_vm.truncate(item.payment_reference_id, -5, '')))])]):_c('span',[_vm._v("--")])])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.table.columns.actions' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex gap-1"},[(
                            item.checkout.checkout_status !==
                              'FINALIZE_SUCCESS'
                          )?_c('div',[(item.checkout.result_description)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                              ("" + (item.checkout.result_description))
                            ),expression:"\n                              `${item.checkout.result_description}`\n                            ",modifiers:{"top":true}}],staticClass:"refund-action refund",on:{"click":function($event){return _vm.onTrigger({
                                invoiceId: item.id,
                                amount: item.amount,
                                symbol: item.currency.symbol || '$',
                              })}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.actions.trigger' ))+" ")]):_c('div',{staticClass:"refund-action refund",on:{"click":function($event){return _vm.onTrigger({
                                invoiceId: item.id,
                                amount: item.amount,
                                symbol: item.currency.symbol || '$',
                              })}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.actions.trigger' ))+" ")])]):_vm._e(),(
                            item.checkout.checkout_status ===
                              'FINALIZE_SUCCESS'
                          )?_c('div',{staticClass:"refund-action refund disabled"},[_vm._v(" "+_vm._s(_vm.$t( 'components.triggerPaymentManagement.actions.trigger' ))+" ")]):_vm._e(),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"id":("more-actions-" + itemIndex),"data":item},on:{"delete":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])])])]):_vm._e()]:_vm._e()]})]}}],null,false,3846085574)})]:_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")]),_c('AmountTriggerAction',{attrs:{"id":_vm.amountTriggerId,"actionData":_vm.triggerPaymentActionData},on:{"refresh-page":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }